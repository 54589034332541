import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales';
import { Row, Col, Modal, Table, FormControl } from 'react-bootstrap';

//components
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox';
import { MembersWidget } from '../../../components/GenericForm/Widgets/UsersWidget';
import { ProjectsWidget } from '../../../components/GenericForm/Widgets/ProjectsWidget';
import DatePicker from '../../../components/DatePicker/DatePicker';
import GpsMap from './GpsMap';
import withLocalization from '~/hoc/withLocalization';

//services
import config from '~/library/config';
import agent from '~/library/agent';

//elements
import Button from '~/components/CustomButton/CustomButton.jsx';
import Input from '../../../elements/Input';

//utils
import customerFeature from '~/utils/customerFeature';
import { longDateFormat } from '../../../utils/dateFormat';

//assets
import CloseModalIcon from '../../../assets/img/CloseApproveModalIcon.svg';

const SendReport = inject(
    'projectStore',
    'userStore',
    'commonStore'
)(
    withLocalization(
        observer(
            class SendReport extends Component {
                constructor(props) {
                    super(props);
                    this.isWeekendRuleActive = props?.commonStore?.config?.client?.data?.extraPayments?.length ?
                        props.commonStore.config.client.data.extraPayments.some(ele => ele.code === 'weekends' && ele.isActive) : false;
                    this.state = {
                        mapOpen: false,
                        email: null,
                        dateRange: { start: moment(new Date()).startOf('month'), end: moment(new Date()) },
                        sending: false,
                        approved_only: false,
                        include_images: true,
                        include_extra: false,
                        include_distance: false,
                        include_travel_expenses: false,
                        include_other_expenses: false,
                        include_inactive_users: true,
                        weekly_payroll_report: false,
                        include_weekend_hours: this.isWeekendRuleActive,
                        include_missing_hours: false,
                        include_absence_vacation: true,
                        is_project_mode: false,
                        include_extra_images: false,
                        iframeURL: 'about:blank',
                        gps: false,
                        weekStart: null,
                        users: [],
                        all_members: false,
                        project: { value: '', label: 'All' },
                        showExcelModal: false,
                        activityTypes: [{ key: 'total_hours_normal', name: 'Total Hours' }],
                        invalidUsers: [],
                        showMergeByProject: false,
                        currentLocale: props?.commonStore?.locale || (config.SERVER_IS_NORWAY ? 'no' : 'en'),
                        updateMembers: false,
                        is_show_project_tax_number: false,
                        include_extra_payment: true
                    };
                    this.handleShowExcelModal = this.handleShowExcelModal.bind(this);
                    this.handleCloseExcelModal = this.handleCloseExcelModal.bind(this);
                    this.handleDownloadExcel = this.handleDownloadExcel.bind(this);
                    this.handleShowZiriusReportModal = this.handleShowZiriusReportModal.bind(this);
                    this.handleWeeklyPayrollReport = this.handleWeeklyPayrollReport.bind(this);
                }

                static getDerivedStateFromProps(nextProps, prevState) {
                    if (nextProps && nextProps.commonStore && nextProps.commonStore.locale !== prevState.currentLocale) {
                        return {
                            currentLocale: nextProps.commonStore.locale
                        }
                    }
                    return null;
                }

                async handleShowExcelModal() {
                    const { commonStore, userStore, t } = this.props;
                    if (this.state.users.length <= 0 && !this.state.all_members) {
                        commonStore.addNotification(t('Choose users'), null, 'error');
                        return;
                    }

                    const mode = this.getReportMode();
                    const hydrated = this.getHydratedParams(commonStore, userStore, mode, { excel: true });

                    const reportInfo = await userStore.getReportInfo(hydrated);

                    const { activityTypes } = reportInfo;
                    const { invalidUsers } = reportInfo;

                    this.setState({
                        activityTypes,
                        invalidUsers,
                        showExcelModal: true,
                        isZirius: false,
                        showMergeByProject: false,
                    });
                }

                async handleShowZiriusReportModal() {
                    const { commonStore, userStore, t } = this.props;
                    if (this.state.users.length <= 0 && !this.state.all_members) {
                        commonStore.addNotification(t('Choose users'), null, 'error');
                        return;
                    }

                    const mode = this.getReportMode();
                    const hydrated = this.getHydratedParams(commonStore, userStore, mode, {
                        excel: true,
                        zirius: true,
                    });

                    const reportInfo = await userStore.getReportInfo(hydrated);

                    const { activityTypes } = reportInfo;
                    const { invalidUsers } = reportInfo;

                    this.setState({
                        activityTypes,
                        invalidUsers,
                        showExcelModal: true,
                        isZirius: true,
                        showMergeByProject: true,
                        isMergeByProject: true,
                    });
                }

                async handleWeeklyPayrollReport(params = {}) {
                    const mode = this.getReportMode();
                    const { t, commonStore } = this.props;
                    if (this.state.users.length <= 0 && mode !== 'vacation' && !this.state.all_members) {
                        commonStore.addNotification(t('Choose users'), null, 'error');
                        return;
                    }
                    if (mode === 'project' && !this.state.project.value) {
                        commonStore.addNotification(t('Choose project'), null, 'error');
                        return;
                    }
                }

                handleCloseExcelModal() {
                    this.setState({ showExcelModal: false, isZirius: false, showMergeByProject: false });
                }

                handleDownloadExcel(e) {
                    const zirius = this.state.isZirius;
                    const isMergeByProject = this.state.isMergeByProject;
                    const { t, commonStore } = this.props;
                    e.preventDefault();
                    const data = new FormData(e.target);
                    const wageActivityToType = {};
                    this.state.activityTypes.forEach((item) => {
                        wageActivityToType[item.key] = data.get('wageType[' + item.key + ']');
                    });

                    this.applyForm({
                        excel: true,
                        wageActivityToType,
                        zirius: zirius,
                        isMergeByProject: isMergeByProject,
                    });
                    this.setState({ showExcelModal: false, isZirius: false, isMergeByProject: false });

                    if (this.state.invalidUsers.length > 0) {
                        commonStore.addNotification(
                            <>
                                {t('Employee ID is not filled for')}
                                {this.state.invalidUsers.map((user) => (
                                    <div>
                                        {user.first_name} {user.last_name}
                                    </div>
                                ))}
                            </>,
                            null,
                            'info',
                            'tc',
                            10
                        );
                    }
                }

                getReportMode() {
                    return this.props.mode || 'default';
                }

                getCustomValues() {
                    const customValues = {};

                    const mode = this.getReportMode();

                    if (mode === 'employee_project') {
                        customValues['approved_only'] = true;
                        customValues['include_weekend_hours'] = this.isWeekendRuleActive;
                        customValues['all_members'] = true;
                        customValues['is_project_mode'] = true;
                    } else if (mode === 'cooperation') {
                        customValues['approved_only'] = true;
                        customValues['consider_overtime'] = true;
                    } else if (mode === 'project') {
                        customValues['include_extra'] = false;
                        customValues['consider_overtime'] = true;
                    }

                    return customValues;
                }

                getMode(mode) {
                    if (mode === 'employee_project' && !this.state.is_project_mode) {
                        return 'employee';
                    } else if (mode === 'project' && this.state.include_extra) {
                        return 'extra';
                    }
                    return mode;
                }

                getHydratedParams(commonStore, userStore, mode, params) {
                    const hydrated = {
                        user_id: userStore.currentUser.id,
                        mode: this.getMode(mode),
                        mail: !!params.email,
                        all_members: this.state.all_members,
                        pdf: params.pdf,
                        excel: !!params.excel,
                        zirius: !!params.zirius,
                        users: this.state.users.join(','),
                        email: params.email ? this.state.email : '',
                        approved_only: this.state.approved_only,
                        project: this.state.project.value,
                        gps: this.state.gps || null,
                        consider_overtime: this.state.consider_overtime || null,
                        projectname: this.state.project.label,
                        start: this.state.dateRange.start.format('YYYY-MM-DD'),
                        end: this.state.dateRange.end.format('YYYY-MM-DD'),
                        weekStart: this.state.weekStart.format('YYYY-MM-DD'),
                        include_images: this.state.include_images || null,
                        include_extra: this.state.include_extra || null,
                        include_extra_images: this.state.include_extra_images || null,
                        include_distance: this.state.include_distance || null,
                        include_travel_expenses: this.state.include_travel_expenses || null,
                        include_other_expenses: this.state.include_other_expenses || null,
                        include_weekend_hours: this.state.include_weekend_hours,
                        include_missing_hours: this.state.include_missing_hours,
                        include_absence_vacation: this.state.include_absence_vacation,
                        is_project_mode: this.state.is_project_mode,
                        weekly_payroll_report: params.weekly_payroll_report,
                        include_inactive_users: this.state.include_inactive_users || null,
                        isSummaryExcel: params.isSummaryExcel,
                        is_show_project_tax_number: this.state.is_show_project_tax_number,
                        isProjectsExcel: params.isProjectsExcel,
                        include_extra_payment: this.state.include_extra_payment
                    };
                    if (params.wageActivityToType) {
                        hydrated.wageActivityToType = params.wageActivityToType;
                    }
                    if (params.zirius) {
                        hydrated.zirius = params.zirius;
                    }
                    if (params.isMergeByProject) {
                        hydrated.isMergeByProject = params.isMergeByProject;
                    }
                    hydrated.preview = !params.pdf && !params.excel;
                    hydrated.authorization = commonStore.token;

                    return hydrated;
                }

                loadData() {
                    const { userStore } = this.props;
                    const userType = userStore.currentUser.user_type;
                    const userSelect = customerFeature('reports_not_for_me');
                    const start =
                        this.getReportMode() === 'gps' ? moment(new Date()) : moment(new Date()).startOf('month');
                    this.setState({
                        dateRange: { start, end: moment(new Date()) },
                        weekStart: moment(new Date()).add(-7, 'days'),
                    });

                    if (userType === 'pm' && userSelect) {
                        this.setState({ users: /* userStore.currentUser.Manages.map(o => `${o}`) */[] });
                    }
                    if (!userSelect || userType === 'member') {
                        this.setState({ users: [userStore.currentUser.id] });
                    }
                }

                componentDidMount() {
                    this.loadData();
                    const customValues = this.getCustomValues();
                    if (Object.keys(customValues).length) {
                        this.setState(customValues);
                    }
                }

                onSelect = (date, field) => {
                    const dr = this.state.dateRange;
                    dr[field] = moment(date);
                    this.setState({ dateRange: dr });
                };

                onEmailChange = (e) => {
                    this.setState({ email: e.target.value });
                };

                onCheckboxChange = (name, value) => {
                    this.setState({ [name]: value });
                    if (name === 'include_inactive_users') {
                        this.setState({ updateMembers: value })
                    }
                };

                onWeekChange = (day) => {
                    this.setState({ weekStart: day });
                };

                applyForm = (params = {}) => {
                    const mode = this.getReportMode();
                    const { t, userStore, commonStore } = this.props;
                    if (commonStore?.config?.client?.data?.basicRules?.hideSensitiveDetailsFromPM && userStore?.currentUser?.user_type === 'pm' && this.state.all_members)
                        return commonStore.addNotification(t('Due to limited permission, you can only generate report of you, Please contact Administator' + '!'), null, 'error');
                    if (this.state.users.length <= 0 && mode !== 'vacation' && !this.state.all_members) {
                        commonStore.addNotification(t('Choose users'), null, 'error');
                        return;
                    }
                    if (mode === 'project' && !this.state.project.value) {
                        commonStore.addNotification(t('Choose project'), null, 'error');
                        return;
                    }

                    if (
                        this.state.weekly_payroll_report &&
                        this.state.dateRange.end.diff(this.state.dateRange.start, 'days') !== 6
                    ) {
                        commonStore.addNotification(t('Date range should be 7 days for weekly report'), null, 'error');
                        return;
                    }


                    const hydrated = this.getHydratedParams(commonStore, userStore, mode, params);
                    if (params.pdf) {
                        if (params.email && (!this.state.email || this.state.email.indexOf('@') < 0)) {
                            commonStore.addNotification(t('Fill email'), null, 'error');
                            return;
                        }
                        if (this.state.email && this.state.email.indexOf('.') < 0) {
                            commonStore.addNotification(t('Enter Valid Email'), null, 'error');
                            return;
                        }
                        if (!params.email) {
                            window.open(
                                `${config.API_ROOT}/dashboard/send_report${agent.convertToGetParams(hydrated)}`
                            );
                        }
                        userStore
                            .sendReport(hydrated)
                            .then((res) => {
                                this.setState({ sending: false });
                                if (params.email) commonStore.addNotification(t('Sent'), null, 'success');
                            })
                            .catch((err) => {
                                this.setState({ sending: false });
                                commonStore.addNotification(err.message || t('Error'), null, 'error');
                            });
                    } else {
                        this.setState({
                            iframeURL: `${config.API_ROOT}/dashboard/send_report${agent.convertToGetParams(hydrated)}`,
                        });
                    }
                };

                gpsMap = () => {
                    this.setState({ mapOpen: true });
                };

                onMemberChange = (id, user) => {
                    const users = user ? user.map((u) => u.value) : [];
                    this.setState({ users });
                };

                onProjectChange = (id, user) => {
                    this.setState({ project: user, is_show_project_tax_number: user.value === '' ? false : this.state.is_show_project_tax_number });
                };

                render() {
                    const { config } = this.props.commonStore;
                    const { t, userStore } = this.props;
                    const { sending, mapOpen } = this.state;
                    const approve = customerFeature('allow_accepting');
                    const mode = this.getReportMode();
                    const userType = userStore.currentUser.user_type;
                    const userSelect = userType !== 'member' && !!customerFeature('reports_not_for_me');
                    const gpsEnabled = !!customerFeature('gps_enabled');
                    const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules
                        ? config.client.data.dateTimeRules
                        : false;
                    return (
                        <div>
                            {mapOpen && (
                                <GpsMap params={this.state} onClose={() => this.setState({ mapOpen: false })} />
                            )}
                            <div className="panel panelReports m-1 p-3">
                                <h4 className="reportsTitle">
                                    {mode === 'cooperation' && t('Payroll report')}
                                    {mode === 'default' && t('Invoice report')}
                                    {mode === 'vacation' && t('Vacation report')}
                                    {mode === 'weekly' && t('Weekly')}
                                    {mode === 'gps' && t('GPS report')}
                                    {mode === 'employee' && t('Employee report')}
                                    {mode === 'employee_project' && t('Employee Payroll report')}
                                    {mode === 'project' && t('Project Report')}
                                </h4>
                                {mode === 'default' && (
                                    <div>
                                        {gpsEnabled && (
                                            <CustomCheckbox
                                                label={t('Include GPS data')}
                                                onChange={(v) => this.onCheckboxChange('gps', v)}
                                                isChecked={this.state.gps}
                                            />
                                        )}
                                        <CustomCheckbox
                                            label={t('Include images')}
                                            onChange={(v) => this.onCheckboxChange('include_images', v)}
                                            isChecked={this.state.include_images}
                                        />
                                        <CustomCheckbox
                                            label={t('Include extra work')}
                                            onChange={(v) => this.onCheckboxChange('include_extra', v)}
                                            isChecked={this.state.include_extra}
                                        />
                                        <CustomCheckbox
                                            label={t('Include extra work images')}
                                            onChange={(v) => this.onCheckboxChange('include_extra_images', v)}
                                            isChecked={this.state.include_extra_images}
                                        />
                                    </div>
                                )}
                                {mode === 'cooperation' && (
                                    <div className="row">
                                        <div className="col-md-6">
                                            {approve && (
                                                <CustomCheckbox
                                                    label={t('Approved only')}
                                                    onChange={(v) => this.onCheckboxChange('approved_only', v)}
                                                    isChecked={this.state.approved_only}
                                                    className={'mt-2'}
                                                />
                                            )}
                                            <CustomCheckbox
                                                label={t('Include Overtime')}
                                                onChange={(v) => this.onCheckboxChange('consider_overtime', v)}
                                                isChecked={this.state.consider_overtime}
                                                className={'mt-2'}
                                            />
                                            <CustomCheckbox
                                                label={t('Show weekend hours')}
                                                onChange={(v) => this.onCheckboxChange('include_weekend_hours', v)}
                                                isChecked={this.state.include_weekend_hours}
                                                className={'mt-2'}
                                                disabled={!this.isWeekendRuleActive}
                                                title={!this.isWeekendRuleActive ? t('Weekend rule is not active in extra payments in control panel') : null}
                                            />
                                            <CustomCheckbox
                                                label={t('Include Absences and Vacations')}
                                                onChange={(v) => this.onCheckboxChange('include_absence_vacation', v)}
                                                isChecked={this.state.include_absence_vacation}
                                                className={'mt-2'}
                                            />
                                            <CustomCheckbox
                                                label={`${t('Include')} ${t('Additional payment')}`}
                                                onChange={(v) => this.onCheckboxChange('include_extra_payment', v)}
                                                isChecked={this.state.include_extra_payment}
                                                className={'mt-2'}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <CustomCheckbox
                                                label={t('Include extra work')}
                                                onChange={(v) => this.onCheckboxChange('include_extra', v)}
                                                isChecked={this.state.include_extra}
                                                className={'mt-2'}
                                            />
                                            <CustomCheckbox
                                                label={t('Include Distance')}
                                                onChange={(v) => this.onCheckboxChange('include_distance', v)}
                                                isChecked={this.state.include_distance}
                                                className={'mt-2'}
                                            />
                                            <CustomCheckbox
                                                label={t('Include Other Expenses')}
                                                onChange={(v) => this.onCheckboxChange('include_other_expenses', v)}
                                                isChecked={this.state.include_other_expenses}
                                                className={'mt-2'}
                                            />
                                            {userType !== "member" &&
                                                <CustomCheckbox
                                                    label={t('Include Inactive Users')}
                                                    onChange={(v) => this.onCheckboxChange('include_inactive_users', v)}
                                                    isChecked={this.state.include_inactive_users}
                                                    className={'mt-2'}
                                                />
                                            }
                                        </div>
                                    </div>
                                )}
                                {mode.includes('employee', 'employee_project') && (
                                    <div>
                                        {approve && (
                                            <CustomCheckbox
                                                label={t('Approved only')}
                                                onChange={(v) => this.onCheckboxChange('approved_only', v)}
                                                isChecked={this.state.approved_only}
                                            />
                                        )}
                                    </div>
                                )}
                                {mode !== 'gps' && (
                                    <div className="row mt-18">
                                        <div className="col-md-6">
                                            <DatePicker
                                                selected={this.state.dateRange.start.toDate()}
                                                onChange={(date) => this.onSelect(date, 'start')}
                                                dateFormat={longDateFormat(dateTimeRules)}
                                                label={t('From')}
                                                defaultDate={moment(new Date()).startOf('month').format('YYYY-MM-DD')}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <DatePicker
                                                selected={this.state.dateRange.end.toDate()}
                                                onChange={(date) => this.onSelect(date, 'end')}
                                                dateFormat={longDateFormat(dateTimeRules)}
                                                label={t('To')}
                                            />
                                        </div>
                                    </div>
                                )}
                                {mode === 'gps' && (
                                    <div>
                                        <div>
                                            <DatePicker
                                                selected={this.state.dateRange.start.toDate()}
                                                onChange={(date) => this.onSelect(date, 'start')}
                                                dateFormat={longDateFormat(dateTimeRules)}
                                                label={t('For date')}
                                            />
                                        </div>
                                        <div className="pt-2">
                                            <CustomCheckbox
                                                label={t('Collapse changes')}
                                                onChange={(v) => this.onCheckboxChange('include_extra', v)}
                                                isChecked={this.state.include_extra}
                                            />
                                        </div>
                                        {userSelect && (
                                            <div>
                                                <h4>{t('User')}</h4>
                                                <MembersWidget
                                                    isMulti={false}
                                                    value={
                                                        this.state.users && this.state.users.length
                                                            ? this.state.users[0]
                                                            : null
                                                    }
                                                    onChange={(id, user) => this.onMemberChange(id, [user])}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                                {mode === 'project' && (
                                    <div className="mt-18">
                                        <CustomCheckbox
                                            label={t('Include Overtime')}
                                            onChange={(v) => this.onCheckboxChange('consider_overtime', v)}
                                            isChecked={this.state.consider_overtime}
                                            className={'mt-2'}
                                        />
                                        <CustomCheckbox
                                            label={t('Include Distance')}
                                            onChange={(v) => this.onCheckboxChange('include_distance', v)}
                                            isChecked={this.state.include_distance}
                                            className={'mt-2'}
                                        />
                                        <CustomCheckbox
                                            label={t('Travel Expenses')}
                                            onChange={(v) => this.onCheckboxChange('include_travel_expenses', v)}
                                            isChecked={this.state.include_travel_expenses}
                                            className={'mt-2'}
                                        />
                                        <br />
                                        <b>{t('Show extra work')}</b>
                                        <br />
                                        <CustomCheckbox
                                            label={t('Show extra work')}
                                            onChange={(v) => this.onCheckboxChange('include_extra', v)}
                                            isChecked={this.state.include_extra}
                                            className={'mt-2'}
                                        />
                                        <CustomCheckbox
                                            label={t('Include extra work images')}
                                            onChange={(v) => this.onCheckboxChange('include_extra_images', v)}
                                            isChecked={this.state.include_extra_images}
                                            className={'mt-2'}
                                        />
                                    </div>
                                )}
                                {mode === 'employee_project' && (
                                    <div className="mt-2">
                                        <CustomCheckbox
                                            label={t('Show weekend hours')}
                                            onChange={(v) => this.onCheckboxChange('include_weekend_hours', v)}
                                            isChecked={this.state.KM}
                                            className={'mt-2'}
                                            disabled={!this.isWeekendRuleActive}
                                            title={!this.isWeekendRuleActive ? t('Weekend rule is not active in extra payments in control panel') : null}
                                        />
                                        <CustomCheckbox
                                            label={t('Show by project name')}
                                            onChange={(v) => this.onCheckboxChange('is_project_mode', v)}
                                            isChecked={this.state.is_project_mode}
                                            className={'mt-2'}
                                        />
                                    </div>
                                )}
                                {mode !== 'vacation' && mode !== 'gps' && userSelect && (
                                    <div className="mt-12">
                                        <CustomCheckbox
                                            label={t('All members/employees')}
                                            onChange={(v) => this.onCheckboxChange('all_members', v)}
                                            isChecked={this.state.all_members}
                                            className={'mt-2'}
                                        />
                                        <div className="mt-2">
                                            <MembersWidget
                                                isMulti
                                                value={this.state.users}
                                                placeholder={t('Select') + '...'}
                                                onChange={(id, user) => this.onMemberChange(id, user)}
                                                IsRequestFromReport={this.state.updateMembers}
                                            />
                                        </div>
                                    </div>
                                )}
                                {userType === 'admin' && mode !== 'vacation' && mode !== 'gps' && userSelect && (
                                    <div className="danger mt-1">
                                        {t(
                                            'Report for all employees can be too big. We recommend to select one employee at a time and try.'
                                        )}
                                    </div>
                                )}
                                {mode !== 'vacation' && (
                                    <div className="mt-10">
                                        <h5>{t('Project')}</h5>
                                        {config.biztype?.data?.allow_multiple_branches && mode === 'cooperation' ?
                                            <div className='d-flex'>
                                                <div className='col-md-6'>
                                                    <ProjectsWidget
                                                        value={this.state.project.value}
                                                        onChange={(id, user) => this.onProjectChange(id, user)}
                                                        allowAll={userType === 'member' || mode === 'project' ? false : true}
                                                    />
                                                </div>
                                                <div className='col-md-6 ml-10'>
                                                    <CustomCheckbox
                                                        label={t('Show Project/Branches Details')}
                                                        onChange={(v) => this.onCheckboxChange('is_show_project_tax_number', v)}
                                                        isChecked={this.state.is_show_project_tax_number}
                                                        className={'mt-2'}
                                                        disabled={this.state.project.value === '' ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            :
                                            <ProjectsWidget
                                                value={this.state.project.value}
                                                onChange={(id, user) => this.onProjectChange(id, user)}
                                                allowAll={userType === 'member' || mode === 'project' ? false : true}
                                            />
                                        }
                                    </div>
                                )}
                                <div className="did-not-work__buttons mt-2">
                                    {sending && <div>Sending</div>}
                                    {mode === 'gps' && (
                                        <Button variant="primary" onClick={() => this.gpsMap()}>
                                            {t('GPS on map')}
                                        </Button>
                                    )}
                                    {!sending && (
                                        <Button variant="primary" fill onClick={() => this.applyForm()}>
                                            {t('Apply')}
                                        </Button>
                                    )}
                                </div>
                            </div>
                            <br />
                            <div className="panel panelReports m-1 p-3">
                                <iframe
                                    style={{ width: '100%', height: '90vh', border: 0 }}
                                    src={this.state.iframeURL}
                                    title='Report'
                                />
                            </div>
                            <div className="panel panelReports m-1 p-3 mt-10">
                                <Input label={t('To email')} value={this.state.email} onChange={this.onEmailChange} placeholder={t('Enter email address')} />
                                <Row className="pt-10 pb-2 reportFooter">
                                    <Col lg={2} md={4} sm={6} className={'mt-2'}>
                                        <Button
                                            variant="primary"
                                            onClick={() => this.applyForm({ pdf: true, email: false })}
                                        >
                                            {t('Download as PDF')}
                                        </Button>
                                    </Col>
                                    <Col lg={2} md={4} sm={6} className={'mt-2'}>
                                        <Button
                                            variant="primary"
                                            onClick={() => this.applyForm({ pdf: true, email: true })}
                                        >
                                            {t('Send PDF by email')}
                                        </Button>
                                    </Col>
                                    {this.props.isProjectReport && (
                                        <Col lg={2} md={4} sm={6} className={'mt-2'}>
                                            <Button
                                                variant="primary"
                                                onClick={() => this.applyForm({ excel: true, email: false, isProjectsExcel: true })}
                                            >
                                                <i className="fa fa-file-excel-o"></i> {t('Project')} {t('Hours')}
                                            </Button>
                                        </Col>
                                    )}
                                    {this.props.isPayRollReport && (
                                        <Col lg={2} md={4} sm={6} className={'mt-2'}>
                                            <Button
                                                variant="primary"
                                                title={t('Summary Excel')}
                                                onClick={() => this.applyForm({ isSummaryExcel: true, email: false })}
                                            >
                                                <i className="fa fa-file-excel-o"></i>{t('Summary Excel')}
                                            </Button>
                                        </Col>
                                    )}
                                    <Col lg={2} md={4} sm={6} className={'mt-2'}>
                                        {this.props.isPayRollReport && this.state.weekly_payroll_report && (
                                            <Button
                                                variant="primary"
                                                onClick={() =>
                                                    this.applyForm({
                                                        weekly_payroll_report: true,
                                                        excel: true,
                                                        email: false,
                                                    })
                                                }
                                            >
                                                {t('Weekly Report')}
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                                <Modal show={this.state.showExcelModal} onHide={this.handleCloseExcelModal}>
                                    <form onSubmit={this.handleDownloadExcel}>
                                        <Modal.Header>
                                            <Modal.Title className="w-100 text-center m-0">
                                                {t('Please enter wage type')}
                                            </Modal.Title>
                                            <img
                                                src={CloseModalIcon}
                                                className="cursor-pointer"
                                                onClick={this.handleCloseExcelModal}
                                                alt='close-btn'
                                            />
                                        </Modal.Header>
                                        <Modal.Body>
                                            {t('Fill corresponding Wage Type ID for Activity Type')}
                                            {this.state.showMergeByProject && (
                                                <div>
                                                    <CustomCheckbox
                                                        onChange={(v) => this.onCheckboxChange('isMergeByProject', v)}
                                                        isChecked={this.state.isMergeByProject}
                                                        name="isMergeByProject"
                                                        label={t('Merge by Project')}
                                                    />
                                                </div>
                                            )}

                                            <Table striped bordered hover size="sm">
                                                <thead>
                                                    <tr>
                                                        <th>{t('ACTIVITY TYPE')}</th>
                                                        <th>{t('WAGE TYPE VALUE')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.activityTypes.map((type) => (
                                                        <tr key={type.key}>
                                                            <td>{type.name}</td>
                                                            <td>
                                                                <FormControl
                                                                    required={true}
                                                                    type={'text'}
                                                                    name={'wageType[' + type.key + ']'}
                                                                    placeholder={t('Wage Type ID')}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={this.handleCloseExcelModal}>
                                                {t('Close')}
                                            </Button>
                                            <Button variant="primary" type="submit">
                                                {t('Download')}
                                            </Button>
                                        </Modal.Footer>
                                    </form>
                                </Modal>
                            </div>
                        </div>
                    );
                }
            }
        )
    )
);

export const AssociateReport = observer(
    class AssociateReport extends Component {
        render() {
            return <SendReport {...this.props} mode="default" />;
        }
    }
);

export const PayrollReport = observer(
    class PayrollReport extends Component {
        render() {
            return <SendReport isProjectReport={true} isPayRollReport={true} {...this.props} mode="cooperation" />;
        }
    }
);

export const VacationReport = observer(
    class VacationReport extends Component {
        render() {
            return <SendReport {...this.props} mode="vacation" />;
        }
    }
);

export const ProjectReport = observer(
    class ProjectReport extends Component {
        render() {
            return <SendReport isProjectReport={true} {...this.props} mode="project" />;
        }
    }
);

export const WeeklyReport = observer(
    class WeeklyReport extends Component {
        render() {
            return <SendReport {...this.props} mode="weekly" />;
        }
    }
);

export const EmployeeReport = observer(
    class EmployeeReport extends Component {
        render() {
            return <SendReport {...this.props} mode="employee" />;
        }
    }
);

export const EmployeeProjectReport = observer(
    class EmployeeProjectReport extends Component {
        render() {
            return <SendReport {...this.props} mode="employee_project" />;
        }
    }
);

export const GpsReport = observer(
    class GpsReport extends Component {
        render() {
            return <SendReport {...this.props} mode="gps" />;
        }
    }
);
