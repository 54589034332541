import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { confirmAlert } from 'react-confirm-alert';
import { Modal, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';

//components
import applicationRouter from '~/hoc/applicationRouter';
import withLocalization from '../../../hoc/withLocalization';
import { TasksWidget } from '../../../components/GenericForm/Widgets/TasksWidget';
import { Accordion, AccordionItem } from '../../../components/Accordion/Accordion';
import TimeSelect from '../../../components/TimeSelect/TimeSelect';
import InputTime from '../../../components/GenericForm/Widgets/InputTime';
import TimelogArray from '../../../components/TimelogArray/TimelogArray';
import DynamicList from '../../../components/DynamicList/DynamicList';
import { AttachmentsWidget } from '../../../components/GenericForm/Widgets/AttachmentsWidget';
import Signature from '../../../components/Signature/Signature';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import { Input, Textarea, NumberInput } from '../../../elements/Input';
import DatePicker from '../../../components/DatePicker/DatePicker';
import { MembersWidget } from '../../../components/GenericForm/Widgets/UsersWidget';
import AcceptFormBlock from './AcceptFormBlock';
import TimePickerInput from '../../../components/TimePicker';
import GPSInfo from './GPSInfo';

//elements
import LoadingSpinner from '../../../elements/LoadingSpinner';

//utils
import customerFeature from '~/utils/customerFeature';
import { getUserExtraPayments } from '~/utils/commonMethods';
import { getMinutesFromTimeInput, minutesToTimeInput } from '../../../utils/timeCalc';
import { longDateFormat, distanceUnitType } from '../../../utils/dateFormat'

//assests
import distanceIcon from '../../../assets/img/distance.svg';
import PlayStart from '../../../assets/img/PlayStart.svg';
import FinishMap from '../../../assets/img/FinishMap.svg';
import manuallyIcon from '../../../assets/img/manuallyIcon.svg';
import startIcon from '../../../assets/img/startIcon.svg';
import endIcon from '../../../assets/img/endIcon.svg';
import CloseModalIcon from '../../../assets/img/CloseApproveModalIcon.svg';

//styles
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../../styles/css/timelogs.css';

class TimelogsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isqucikChecklist: false,
            isDateChange: false,
            automaticOvertime: [],
            automaticOvertimeObject: {}
        }
        this.props.timelogStore.updateLoaderState(true);
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(previousProps) {
        if (previousProps.router?.location?.pathname !== this.props.router?.location?.pathname) {
            this.props.timelogStore.load(this.props.router.params.id).then((timelog) => {
                this.handleChange('for_date', timelog.timelog.for_date, true);
            });
        }
    }

    handleQuickChecklistModalClose = (isInjury) => {
        if (isInjury === true) {
            this.handleChange('data.isInjury', isInjury);
            this.reportInjuryDuringWork();
        }
        this.setState({ isqucikChecklist: false });
    };

    reportInjuryDuringWork = async () => {
        const { timelogStore, commonStore, t } = this.props;
        const { currentEntity } = timelogStore;
        await timelogStore.reportInjury(currentEntity, currentEntity).then(result => {
            if (!result || !result.id) {
                commonStore.addNotification(t(result.message || 'Error'), null, 'error');
            } else {
                commonStore.addNotification(t('Very sorry to know about your injury, PM and management has been informed'), null, 'success');
            }
        });
    }

    checkIfOvertimeEditable(clientData, currentUser, currentEntity) {
        if (clientData && clientData.basicRules && clientData.basicRules.overtimeAutomaticMode) {
            return { addEdit: false, message: 'Overtime is being calculated automatically' };
        }
        else if (currentEntity && currentEntity.status === "draft") {
            return { addEdit: false, message: 'Cannot add overtime while timesheet in draft' };
        }
        else if (clientData && clientData.loginRules && clientData.loginRules.autoTimelogs && clientData.loginRules.autoTimelogs === 'gps') {
            if (clientData.loginRules.pmManagesOvertime) {
                return (currentUser && currentUser.user_type !== 'member') ?
                    { addEdit: true, message: 'Add Overtime' } :
                    { addEdit: false, message: 'Only PM/Admin can manage overtime' };
            } else {
                return ((currentUser && currentEntity && currentUser.id === currentEntity.user_id) || currentUser.user_type === 'admin') ?
                    { addEdit: true, message: 'Add Overtime' } :
                    { addEdit: false, message: 'PM cannot manage overtime' };
            }
        }
        else return { addEdit: true, message: 'Add Overtime' };
    }


    visualState() {
        const { timelogStore, add, userStore, commonStore } = this.props;
        const { user_type, id: userId, data: userData } = userStore.currentUser;
        const { config } = commonStore;
        const manualDisabled = !!userData.disable_manual_log;
        const pauseButtonEnabled = !!userData.enable_pause_button;
        const gpsEnabled = customerFeature('gps_enabled');
        const { currentEntity } = timelogStore;
        const allowEditing =
            (user_type === 'pm' || user_type === 'admin') &&
            customerFeature('pm_allow_editing_all') &&
            !customerFeature('timelog_hide_names');
        const showStatus =
            !add &&
            user_type === 'pm' &&
            customerFeature('allow_accepting') &&
            currentEntity.task_id &&
            currentEntity.status !== 'draft';
        const allowSave = currentEntity.status === 'draft' || currentEntity.user_id === userId || allowEditing;
        const normalEditing = currentEntity.task_id && currentEntity.tracker_status !== 'na';
        const showStartButtons = currentEntity.status === 'draft' && currentEntity.task_id && currentEntity.tracker_status === 'na';
        const isManualMode = normalEditing && currentEntity.tracker_status === 'manual';
        const isTrackingMode =
            normalEditing && currentEntity.status === 'draft' && currentEntity.tracker_status !== 'manual';

        //adding condition for autoShiftplan//
        const isSystemEntry = (currentEntity.gps_data && currentEntity.gps_data.systemEntry) ? currentEntity.gps_data.systemEntry : false;
        const trackerIsRunning =
            currentEntity.status === 'draft' &&
            (currentEntity.tracker_status === 'started' || currentEntity.tracker_status === 'break' || isSystemEntry);
        const allowTimeEditing = !isTrackingMode;
        const showFinishButtons = trackerIsRunning;
        const { addEdit: isOvertimeEditable, message: overtimeTooltipMessage } = this.checkIfOvertimeEditable(config.client.data, userStore.currentUser, currentEntity);
        return {
            gpsEnabled,
            allowEditing,
            allowSave,
            showStatus,
            normalEditing,
            showStartButtons,
            showFinishButtons,
            isManualMode,
            isTrackingMode,
            trackerIsRunning,
            allowTimeEditing,
            manualDisabled,
            pauseButtonEnabled,
            isOvertimeEditable,
            overtimeTooltipMessage
        };
    }

    timeAction = async (act) => {
        const { timelogStore, t } = this.props;
        const { currentEntity } = timelogStore;

        if (act === 'start') {
            timelogStore.startByButton(currentEntity.id, {});
        }
        if (act === 'finish') {
            await timelogStore.finishByButton(currentEntity, {}).then(result => {
                if (result.hasError) {
                    const { errorMessage } = result;
                    confirmAlert({
                        title: t('Timesheet hours exceeded!'),
                        message: errorMessage,
                        buttons: [
                            {
                                label: t('Auto Adjust'),
                                onClick: async () => {
                                    return timelogStore.finishByButton(currentEntity, { autoAdjust: true }).then(response => {
                                        this.afterSaveStateAndErrorHandling(response);
                                    })
                                },
                            },
                            {
                                label: t('Cancel'),
                                onClick: () => { },
                            }
                        ],
                    });
                }
                else
                    this.afterSaveStateAndErrorHandling(result);
            });
        }
        if (act === 'break') {
            const isPaused = currentEntity.tracker_status === 'break';
            await timelogStore.breakByButton(currentEntity.id, isPaused ? 'stop' : 'start', false);
            this.loadData();
        }
        if (act === 'manual') {
            timelogStore.setManualDefaults();
            this.intermediateSave();
        }
    };

    afterSaveStateAndErrorHandling = (result) => {
        const { commonStore, t } = this.props;
        const { client } = commonStore.config;

        if (!result || !result.id) {
            commonStore.addNotification(t(result.message || 'Error'), null, 'error');
        } else {
            if (client && client.data && client.data.basicRules && client.data.basicRules.allowQucikChecklist)
                this.setState({ isqucikChecklist: true });
        }
    }

    afterSave = (result) => {
        const { commonStore, t } = this.props;
        if (!result?.timelog || !result?.timelog.id) {
            commonStore.addNotification(t(result.message || 'Error'), null, 'error');
            return false;
        }
        commonStore.addNotification(t('Saved'), null, 'success');
        this.getBack()
        return true;
    };

    getBack() {
        const page = (this.props.router.location.state && this.props.router.location.state.page) ?
            this.props.router.location.state.page : 0;
        this.props.router.navigate("/admin/timelogs", { state: { page: page } })
    }

    fullSave = async (doPublish) => {
        const { timelogStore } = this.props;
        const { commonStore } = this.props;
        const { data: clientData } = commonStore.config.client;
        const { currentEntity, originalEntity } = timelogStore;
        const { t } = this.props;

        if (currentEntity.total_hours_overall < currentEntity.total_hours_overtime) {
            commonStore.addNotification(t('Work time is less than overtime'), null, 'error');
            return;
        }

        const internalSave = () => {
            const { commonStore } = this.props;
            const overTimeMaxHours = commonStore.config
                && commonStore.config.client
                && commonStore.config.client.data.basicRules
                && commonStore.config.client.data.basicRules.overtimeCalcRules
                && commonStore.config.client.data.basicRules.overtimeCalcRules.max_hours ? Number(commonStore.config.client.data.basicRules.overtimeCalcRules.max_hours) : 0;

            const overTimeMaxMins = commonStore.config
                && commonStore.config.client
                && commonStore.config.client.data.basicRules
                && commonStore.config.client.data.basicRules.overtimeCalcRules
                && commonStore.config.client.data.basicRules.overtimeCalcRules.max_minutes ? Number(commonStore.config.client.data.basicRules.overtimeCalcRules.max_minutes) : 0;

            const overTimeThresoldMinutes = commonStore.config
                && commonStore.config.client
                && commonStore.config.client.data.basicRules
                && commonStore.config.client.data.basicRules.overtimeCalcRules
                && commonStore.config.client.data.basicRules.overtimeThresoldMinutes ? Number(commonStore.config.client.data.basicRules.overtimeThresoldMinutes) : 0;

            const isAutomaticOvertime = !!(commonStore.config && commonStore.config.client &&
                commonStore.config.client.data.basicRules &&
                commonStore.config.client.data.basicRules.overtimeAutomaticMode &&
                commonStore.config.client.data.basicRules.overtimeCalcRules &&
                commonStore.config.client.data.basicRules.overtimeCalcRules.calc_period &&
                commonStore.config.client.data.basicRules.overtimeCalcRules.calc_period !== 'none');

            const memberOvertime = commonStore.config
                && commonStore.config.client
                && commonStore.config.client.data.basicRules
                && commonStore.config.client.data.basicRules.overtimeCalcRules
                && commonStore.config.client.data.basicRules.overtimeCalcRules.calc_percent ? Number(commonStore.config.client.data.basicRules.overtimeCalcRules.calc_percent) : 0;

            const totalOvertimeThresholdInMins = overTimeMaxHours * 60 + overTimeMaxMins + overTimeThresoldMinutes;
            const maxDailyHoursInMins = isAutomaticOvertime ? totalOvertimeThresholdInMins : 9 * 60;

            if (getMinutesFromTimeInput(currentEntity.total_hours_normal) > maxDailyHoursInMins &&
                getMinutesFromTimeInput(currentEntity.total_hours_overtime) === 0 &&
                !clientData.basicRules.allow_overtime_limit) {
                setTimeout(() => {
                    if (isAutomaticOvertime) {
                        confirmAlert({
                            title: t('Confirm overtime'),
                            message:
                                t('Total registered hours are ') + minutesToTimeInput(getMinutesFromTimeInput(currentEntity.total_hours_normal)) + t(' for the day. ')
                                + minutesToTimeInput((getMinutesFromTimeInput(currentEntity.total_hours_normal) - totalOvertimeThresholdInMins)) + t(' Hours/min. of these are automatically added as overtime of ')
                                + memberOvertime + '%. ' + t('Please cancel and update if this is not correct or click continue.'),
                            buttons: [
                                {
                                    label: t('Cancel'),
                                    onClick: () => { },
                                },
                                {
                                    label: t('Continue'),
                                    onClick: () => {
                                        this.fullSaveInternal(doPublish);
                                    },
                                },
                            ],
                        });
                    } else {
                        confirmAlert({
                            title: t('Confirm overtime'),
                            message: t(
                                'You have registered more time than your standard working day, do you want to register overtime?'
                            ),
                            buttons: [
                                {
                                    label: t('Yes'),
                                    onClick: () => { },
                                },
                                {
                                    label: t('No'),
                                    onClick: () => {
                                        this.fullSaveInternal(doPublish);
                                    },
                                },
                            ],
                        });
                    }
                }, 250);
            }
            else {
                this.fullSaveInternal(doPublish);
            }
        };

        if (currentEntity.status !== 'draft') {
            const enableCheck = clientData.basicRules && !clientData.basicRules.allowManualTimelogChange;
            if (enableCheck && (currentEntity.from !== originalEntity.from || currentEntity.to !== originalEntity.to)) {
                confirmAlert({
                    title: t('Confirm time change'),
                    message: t('You have changed from/to times, this will switch timelog to manual'),
                    buttons: [
                        {
                            label: t('Yes, proceed'),
                            onClick: () => {
                                timelogStore.switchToManual();
                                internalSave();
                            },
                        },
                        {
                            label: t('Cancel'),
                            onClick: () => { },
                        },
                    ],
                });
                return;
            }
        }
        internalSave();
    };

    fullSaveInternal = async (doPublish) => {
        const { timelogStore, commonStore, t } = this.props;
        if (doPublish) {
            this.handleChange('status', 'active');
            this.handleChange('addDistance', doPublish);
        }
        try {
            await timelogStore.save().then(response => {
                if (response.hasError) {
                    const { errorMessage } = response;
                    confirmAlert({
                        title: t('Timesheet hours exceeded!'),
                        message: errorMessage,
                        buttons: [
                            {
                                label: t('Auto Adjust'),
                                onClick: async () => {
                                    const result = await timelogStore.save({ autoAdjust: true });
                                    this.afterSave(result);
                                },
                            },
                            {
                                label: t('Adjust Manually'),
                                onClick: () => { },
                            }
                        ],
                    });
                }
                else {
                    this.afterSave(response);
                }
            });
        } catch (e) {
            if (e && e.name === 'ValidationError') {
                commonStore.addNotification(t(e.message || 'Error'), null, 'error');
            }
            return;
        }
    };

    handleChange = (name, value, isAuto = false) => {
        const { timelogStore, commonStore } = this.props;
        let today = moment(new Date()).format('YYYY-MM-DD')
        if (name === 'for_date') {
            this.setState({ isDateChange: moment(value).format('YYYY-MM-DD') < today })
        }
        timelogStore.changeEntity(name, value, null, isAuto);
        timelogStore.changeAutoOvertimeProperties(name, timelogStore.currentEntity, commonStore.config);
    };

    handleChangeOvertimes = (overtime, value) => {
        const { timelogStore } = this.props;
        const { code } = overtime;
        const { currentEntity } = timelogStore;
        const data = currentEntity.data ? Object.assign({}, currentEntity.data) : {};
        data.overtimes = data.overtimes || {};
        data.overtimes[code] = {
            ...overtime,
            value,
        };
        var overtimes = JSON.parse(JSON.stringify(data.overtimes));
        let newOvertimes = overtimes;

        for (let i = 0; i < Object.keys(overtimes).length; i++) {
            const consideredData = overtimes[Object.keys(overtimes)[i]];
            if (consideredData.value === "00:00") {
                delete newOvertimes[Object.keys(overtimes)[i]];
            }
        }
        data.overtimes = newOvertimes;
        timelogStore.changeEntity('data', data, 'overtime');
    };

    handleTaskChange = async (value, full) => {
        const { timelogStore, t, commonStore } = this.props;
        const { currentEntity } = timelogStore;
        if (currentEntity.task_id && parseInt(currentEntity.task_id) !== parseInt(value) && commonStore?.config?.client?.calculate_task_distance_with_company) {
            confirmAlert({
                title: t('Are You Sure') + '?',
                message: t('Updating task in timelog will updated the distance accordingly. Are you sure')+ '?',
                buttons: [
                    {
                        label: t('Yes'),
                        onClick: async () => {
                            return this.handleTaskChangeWithDistance(value, full, true);
                        },
                    },
                    {
                        label: t('Cancel'),
                        onClick: () => { },
                    }
                ],
            });
        }
        else
            this.handleTaskChangeWithDistance(value, full)
        
    };

    handleTaskChangeWithDistance = (value, full, addDistance) => {
        const { timelogStore} = this.props;
        const { currentEntity } = timelogStore;
        this.handleChange('data.workplace', full.address);
        this.handleChange('project_id', full.project_id);
        this.handleChange('task_id', value);
        if (addDistance) this.handleChange('addDistance', addDistance);
        if (full.start_time && !currentEntity.from) {
            this.handleChange('from', minutesToTimeInput(full.start_time, false));
        }
        if (full.end_time && !currentEntity.to) {
            this.handleChange('to', minutesToTimeInput(full.end_time, false));
        }
        if (full.description) {
            this.handleChange('description', full.description);
        }
        const gpsData = currentEntity.gps_data || {};
        if (full.gps_data) {
            gpsData.task = full.gps_data;
        }
        this.handleChange('gps_data', gpsData);
        this.intermediateSave();
    }

    intermediateSave = async () => {
        const { timelogStore, commonStore, t } = this.props;
        const { currentEntity } = timelogStore;
        try {
            await timelogStore.save(currentEntity, false, true);
            commonStore.addNotification(t('Saved'), null, 'success');
        } catch (e) { }
    };

    handleUserChange = (value, full) => {
        this.handleChange('user_id', value);
        this.handleChange('PM_CHANGE', true);
        this.intermediateSave();
    };

    async loadData() {
        const { timelogStore, commonStore } = this.props;
        let getId = (this.props.router.location.state && this.props.router.location.state.timelogs) ?
            this.props.router.location.state.timelogs :
            this.props.router.params && this.props.router.params.id ?
                this.props.router.params.id
                : null;
        if (!getId) {
            getId = await timelogStore.getPossibleDraftId();
        }
        timelogStore.load(getId).then((timelog) => {
            this.handleChange('for_date', timelog.timelog.for_date, true);
        });
        let arr = [], arrObject = [];
        if (commonStore.config.client.data.basicRules.overtimeAutomaticMode) {
            const code = commonStore.config.client.data.basicRules.overtimeCalcRules.calc_percent;
            arr.push({
                name: `Automatic Overtime ${commonStore.config.client.data.basicRules.overtimeCalcRules.calc_percent}%`,
                code: `${commonStore.config.client.data.basicRules.overtimeCalcRules.calc_percent}`,
                value: minutesToTimeInput(timelogStore.currentEntity._total_hours_overtime, false)
            })

            arrObject = {
                [code]: arr[0]
            }
            this.setState({ automaticOvertime: arr, automaticOvertimeObject: arrObject });
        }
        return null;
    }

    async handleChangeStatus() {
        this.loadData();
    }

    render() {
        const { timelogStore, add, commonStore, t, userStore, id } = this.props;
        const { data: clientData } = commonStore.config.client;
        const { data: biztypeData } = commonStore.config.biztype;
        const { user_type } = userStore.currentUser;
        const { loading, currentEntity, timelogShift } = timelogStore;
        const {
            task_id,
            User,
            user_id,
            from,
            to,
            total_hours_normal,
            total_hours_overtime,
            total_hours_overall,
            data,
            for_date,
            Attachments,
            description,
            status,
            tracker_running,
            _total_hours_normal,
            _total_hours_overtime,
            employeeAssignedForEmergencyShift
        } = currentEntity;
        const { signature } = data;

        const tipAmount = data.tipInfo && data.tipInfo.amount ? data.tipInfo.amount : '';
        const tipEditor = clientData.tipRules && clientData.tipRules.tipEditor;
        let overtimes = data.overtimes ? data.overtimes : {};
        let extraPaymentsInTimeSheet = data.extraPayments ? data.extraPayments : {};
        if (Object.keys(this.state.automaticOvertimeObject).length)
            overtimes = this.state.automaticOvertimeObject
        let configOvertimes = clientData.basicRules.overtime_types || [];
        if (this.state.automaticOvertime.length)
            configOvertimes = [this.state.automaticOvertime[0]]
        const configExtraExpenses = clientData.extendedRules.expense_types || [];
        const configExtraWorks = clientData.extendedRules.extrawork_types || [];
        const quickCheckListText = clientData.basicRules.quickCheckListText || 'Were you injured today at work site?';
        const configStartTime = clientData.basicRules.startTimeRules &&
            clientData.basicRules.startTimeRules.start
            ? clientData.basicRules.startTimeRules.start : '08:00';
        const hideBreakButton = clientData.basicRules && clientData.basicRules.hideBreakButton ? clientData.basicRules.hideBreakButton : false;
        const { config } = commonStore;
        const dateTimeRules = clientData && clientData.dateTimeRules ? clientData.dateTimeRules : false;
        const format = dateTimeRules && dateTimeRules.time_format ? dateTimeRules.time_format : 'hh:mm';
        const alloWManualAdd = clientData.loginRules && clientData.loginRules.autoTimelogs
            && clientData.loginRules.autoTimelogs === 'gpsmanual' && user_type === 'member'
            ? false : true;
        const breakRules = config.client && config.client.data && config.client.data.basicRules &&
            config.client.data.basicRules.breakTimeRules.minutes ?
            config.client.data.basicRules.breakTimeRules.minutes : false;
        const __extraPaymentRules = getUserExtraPayments(User, clientData.extraPayments, for_date, timelogShift, employeeAssignedForEmergencyShift);
        if (loading) {
            return <LoadingSpinner />;
        }
        return (
            <>
                <div className="primary-page" style={{ padding: 0, margin: 0 }}>
                    <div className="row justify-content-end mb-2">
                        <div className='col-md-6 col-sm-12'>
                            <h3 className="primary-page__title">
                                {t('Register working hours')}
                                {status === 'draft' && (
                                    <div className="primary-page__title__draft">
                                        <b>{t('This is a draft work log')}</b>
                                    </div>
                                )}
                            </h3>
                        </div>
                        <div className='col-md-6 col-sm-12 d-flex justify-content-end'>
                            {tracker_running &&
                                <div className='d-flex mt-10 me-2'>
                                    <i className="fas fa-ban" style={{ color: "#d93636" }} title={t('You cannot change the date when worklog is in draft')}></i>
                                </div>
                            }
                            <div className='customize-datepicker'>
                                <DatePicker
                                    selected={new Date(moment(for_date).format('MM/DD/YYYY'))}
                                    onChange={(date) => this.handleChange('for_date', date)}
                                    dateFormat={longDateFormat(dateTimeRules)}
                                    maxDate={new Date()}
                                    bgGrey={true}
                                    disabled={tracker_running === true ? true : false}
                                />
                            </div>
                        </div>
                    </div>
                    <Accordion preExpanded={['time', 'km', 'extrapass', 'otherexp', 'extraw', 'signature', 'final']}>
                        <AccordionItem title={t('Register Time')} uuid="time">
                            {user_type !== 'member' && (
                                <div className="row mb-3 ">
                                    <div className="col-md-6">
                                        <div className="form-group field field-string">
                                            <label className="control-label">{t('Select Member')} *</label>
                                            {this.visualState().allowEditing && (
                                                <MembersWidget
                                                    className="form-control"
                                                    onChange={(value, full) => this.handleUserChange(value, full)}
                                                    value={user_id}
                                                    defaultIfOne
                                                    style={{ borderRadius: 10 }}
                                                />
                                            )}
                                            {!this.visualState().allowEditing && User && (
                                                <div>
                                                    {User.first_name} {User.last_name}
                                                </div>
                                            )}
                                            {!this.visualState().allowEditing && !User && (
                                                <div>
                                                    {userStore.currentUser.first_name} {userStore.currentUser.last_name}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group field field-string mb-3">
                                        <label className="control-label">{t('Select Task')} *</label>
                                        <TasksWidget
                                            className="form-control"
                                            onChange={(value, full) => this.handleTaskChange(value, full)}
                                            value={task_id}
                                            defaultIfOne
                                            filterByUser={user_id}
                                        />
                                    </div>
                                    <div className="form-group field field-string mb-4 border-radius-10">
                                        <label className="control-label">{t('Workplace')}</label>
                                        <input
                                            className="form-control border-radius-10"
                                            type="text"
                                            value={data.workplace ? (data.workplace === 'null' ? '' : data.workplace) : ''}
                                            placeholder={t('Workplace address')}
                                            onChange={(e) => this.handleChange('data.workplace', e.target.value)}
                                        />
                                    </div>
                                </div>
                                {currentEntity.user_image_attachment_id &&
                                    <div className="col-md-6">
                                        <div style={{ display: 'flex', justifyContent: 'right', height: '160px' }}>
                                            <img
                                                src={`${config.UPLOADS_API_ENDPOINT}/${currentEntity.user_image_attachment_id}`} alt=''
                                                style={{ maxHeight: '140px', maxWidth: '200px', marginTop: '0.4rem' }}>
                                            </img>
                                        </div>
                                    </div>
                                }
                            </div>
                            {!!this.visualState().showStartButtons && !!this.visualState().manualDisabled && (
                                <div className="timelog-action-block-new mt-3">
                                    <span className="timelog-action-block-label mb-4">{t('Press "Start" to run timer')}</span>
                                    <div className="timelog-action-block-button">
                                        <Button fill dynamic_lg
                                            onClick={() => this.timeAction('start')}
                                            disabled={this.state.isDateChange}>
                                            <img className='me-2' src={PlayStart} alt='start' />
                                            {t('Start')}
                                        </Button>
                                    </div>
                                </div>
                            )}
                            {!!this.visualState().showStartButtons && !this.visualState().manualDisabled && (
                                <div className="timelog-action-block-new mt-3">
                                    <span className="timelog-action-block-label mb-4">
                                        {t('You can create hours manually or just press "Start" to run timer')}
                                    </span>
                                    <div className="timelog-action-block-button">
                                        <Button fill dynamic_lg
                                            onClick={() => this.timeAction('start')}
                                            disabled={this.state.isDateChange}
                                            title={this.state.isDateChange ? 'You cannot Start working hours for past date' : ''}
                                        >
                                            <div className='d-flex justify-content-center'>
                                                <img className='me-3' src={PlayStart} alt='start' />
                                                {t('Start')}
                                            </div>
                                        </Button>
                                        {alloWManualAdd &&
                                            <Button onClick={() => this.timeAction('manual')} className='timelogs-custom-button-transparent w-100'>
                                                <img className='me-2' src={manuallyIcon} alt='start' />
                                                {t('Manual')}
                                            </Button>
                                        }
                                    </div>
                                </div>
                            )}
                            {!!this.visualState().showFinishButtons && !this.visualState().pauseButtonEnabled && (
                                <div className="timelog-action-block-new mt-3">
                                    <span className="timelog-action-block-label mb-4">{t('Finish the work')}</span>
                                    <div className="timelog-action-block-button">
                                        <Button fill dynamic_lg onClick={() => this.timeAction('finish')}>
                                            <img className='me-2' src={FinishMap} alt='start' />
                                            {t('Finish')}
                                        </Button>
                                    </div>
                                </div>
                            )}
                            {!!this.visualState().showFinishButtons && !!this.visualState().pauseButtonEnabled && (
                                <div className="timelog-action-block-new mt-3">
                                    <span className="timelog-action-block-label mb-4">
                                        {t('You can finish the work or take/end the break')}
                                    </span>
                                    <div className="timelog-action-block-button">
                                        <Button onClick={() => this.timeAction('finish')} fill dynamic_lg>
                                            <img className='me-2' src={FinishMap} alt='start' />
                                            {t('Finish')}
                                        </Button>
                                        {!hideBreakButton &&
                                            <Button wd onClick={() => this.timeAction('break')}>
                                                {currentEntity.tracker_status === 'break'
                                                    ? t('Finish break')
                                                    : t('Start break')}
                                            </Button>
                                        }
                                    </div>
                                </div>
                            )}

                            <Modal
                                show={this.state.isqucikChecklist}
                                onHide={this.handleQuickChecklistModalClose}>
                                <Modal.Header>
                                    <Modal.Title className="w-100 text-center m-0">{t('Quick Checklist')}</Modal.Title>
                                    <img
                                        src={CloseModalIcon}
                                        className="cursor-pointer"
                                        onClick={() => this.setState({ accepting: null })}
                                    />
                                </Modal.Header>
                                <Modal.Body>
                                    {t(quickCheckListText)}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.handleQuickChecklistModalClose(true)}>
                                        {t('Yes')}
                                    </Button>
                                    <Button variant="secondary" onClick={this.handleQuickChecklistModalClose}>
                                        {t('No')}
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            {currentEntity.task_id && (
                                <>
                                    {!this.visualState().showStartButtons && (
                                        <div className="mt-4">
                                            <div className='row timelog-grid-time-card'>
                                                <Col xs={12} md={6} className='timelog-grid-item-1 justify-content-space-between card-timelog-grid-item'>
                                                    <div className="d-flex align-items-center">
                                                        <i className="timelog-grid__icon">
                                                            <img src={startIcon} alt="Start" />
                                                        </i>
                                                        <span className="timelog-grid__text">{t('Start')}</span>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        {this.visualState().allowTimeEditing && (
                                                            <TimePickerInput
                                                                style={{ width: 150 }}
                                                                value={from}
                                                                format={format === "hh:mm" ? "HH:mm" : format}
                                                                onChange={value => this.handleChange('from', value)}
                                                                className="worklog-input border-radius-10"
                                                            />
                                                        )}
                                                        {!this.visualState().allowTimeEditing && (
                                                            <span className="timelog-grid__input-text">{from}</span>
                                                        )}
                                                        {from < configStartTime && (
                                                            <i
                                                                className="fa fa-warning"
                                                                style={{ color: '#FF1E50' }}
                                                                title={t('Starting before') + ' ' + configStartTime}
                                                            />
                                                        )}
                                                        <span className="timelog-grid__info">
                                                            {t('The start time should belong to the time interval')} (
                                                            {clientData.basicRules.startTimeRules.start} -{' '}
                                                            {clientData.basicRules.startTimeRules.end})
                                                        </span>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={6} className='timelog-grid-item-2 justify-content-space-between card-timelog-grid-item'>
                                                    <div className='d-flex align-items-center'>
                                                        <i className="timelog-grid__icon">
                                                            <img src={endIcon} alt="Stop" />
                                                        </i>
                                                        <span className="timelog-grid__text">{t('End')}</span>
                                                    </div>
                                                    <div className='d-flex align-items-center'>
                                                        {this.visualState().allowTimeEditing && (
                                                            <TimePickerInput
                                                                style={{ width: 150 }}
                                                                value={to}
                                                                format={format === "hh:mm" ? "HH:mm" : format}
                                                                onChange={value => this.handleChange('to', value)}
                                                                className="worklog-input border-radius-10"
                                                            />
                                                        )}
                                                        {!this.visualState().allowTimeEditing && (
                                                            <span className="timelog-grid__input-text">
                                                                {this.visualState().trackerIsRunning ? t('Still working') : to}
                                                            </span>
                                                        )}
                                                        <span className="timelog-grid__info">
                                                            {t('The start time should belong to the time interval')} ({from} -{' '}
                                                            {clientData.basicRules.startTimeRules.end})
                                                        </span>
                                                    </div>
                                                </Col>
                                            </div>
                                            <div className='row timelog-grid-time-card'>
                                                <Col xs={12} md={6} className='timelog-grid-item-1 justify-content-space-between align-items-center card-timelog-grid-item'>
                                                    <div>
                                                        <span className="timelog-grid__text ml-30">
                                                            {t('Breaktime')}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        {add && (
                                                            <TimeSelect
                                                                value={currentEntity.break}
                                                                onChange={(option) => this.handleChange('break', option.value)}
                                                                max={120}
                                                                className={currentEntity.break < breakRules ? "dangerTimeInput" : ""}
                                                                disabled={hideBreakButton}
                                                            />
                                                        )}
                                                        {!add && (currentEntity.break || currentEntity.break === 0) && (
                                                            <InputTime
                                                                value={minutesToTimeInput(currentEntity.break, false)}
                                                                onChange={(e) =>
                                                                    this.handleChange('break', getMinutesFromTimeInput(e))
                                                                }
                                                                className={currentEntity.break < breakRules ? "big-input text-center dangerTimeInput" : "big-input text-center"}
                                                                disabled={hideBreakButton}
                                                            />
                                                        )}
                                                        {currentEntity.tracker_status === 'break' &&
                                                            currentEntity.data.breakStarted && (
                                                                <span style={{ paddingLeft: 10 }}>
                                                                    {`${t('Break started at')} ${minutesToTimeInput(
                                                                        currentEntity.data.breakStarted,
                                                                        true
                                                                    )}`}
                                                                </span>
                                                            )}
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={6} className='timelog-grid-item-2 justify-content-space-between card-timelog-grid-item'>
                                                    <div className='d-flex align-items-center'>
                                                        <span className="timelog-grid__text ml-30">{t('Total')}</span>
                                                    </div>
                                                    <InputTime disabled value={total_hours_overall} className="big-input text-center" />
                                                </Col>
                                            </div>
                                        </div>
                                    )}
                                    {!!currentEntity.task_id && (
                                        <GPSInfo data={currentEntity.gps_data} mode="form" full={currentEntity} />
                                    )}
                                    {this.visualState().normalEditing && (
                                        <>
                                            <h3 className="timelog-grid__title">{t('Overtimes')}</h3>
                                            <div className="row timelog-grid-time-card">
                                                {configOvertimes &&
                                                    configOvertimes.map((overtime, index) => (
                                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className='timelog-grid-overtime-item justify-content-space-between card-timelog-grid-item' key={`${index}_${overtime.code}`}>
                                                            {/* <div key={`${index}_${overtime.code}`}> */}
                                                            <div className='d-flex align-items-center'>
                                                                <span className="timelog-grid__text timelog-grid__text-capitalize w-100">
                                                                    {overtime.name}
                                                                </span>
                                                            </div>
                                                            <OverlayTrigger
                                                                key={overtime.name}
                                                                placement="top"
                                                                overlay={<Tooltip id="tooltip-top">{t(this.visualState().overtimeTooltipMessage)}</Tooltip>}
                                                            >
                                                                <div>
                                                                    <InputTime
                                                                        value={
                                                                            overtimes[overtime.code]
                                                                                ? overtimes[overtime.code].value
                                                                                : '00:00'
                                                                        }
                                                                        disabled={!this.visualState().isOvertimeEditable}
                                                                        className="big-input text-center"
                                                                        onChange={(e) => this.handleChangeOvertimes(overtime, e)}
                                                                    />
                                                                </div>
                                                            </OverlayTrigger>
                                                            {/* </div> */}
                                                        </Col>
                                                    ))}
                                            </div>
                                            {this.state?.automaticOvertime.length ?
                                                <div className='font-size-15'>
                                                    {t('Overtime is automatically calculated based on the settings configured in the control panel')}.
                                                </div>
                                                : null}
                                        </>
                                    )}
                                    <>
                                        <h3 className="timelog-grid__title">{t('Additional Payments')}</h3>
                                        <div className="row timelog-grid-time-card">
                                            {__extraPaymentRules?.map((extraPayment, index) => (
                                                <Col key={`${index}_${extraPayment.code}`}
                                                    xs={12} md={6}
                                                    className='timelog-grid-extra-payment-item justify-content-space-between card-timelog-grid-item'>
                                                    <div className='d-flex align-items-center'>
                                                        <span className="timelog-grid__text timelog-grid__text-capitalize w-100"
                                                            title={extraPayment.description ? extraPayment.description : extraPayment.name}>
                                                            {extraPayment.name}
                                                        </span>
                                                        {extraPayment.hasConfigIssue &&
                                                            <OverlayTrigger
                                                                key={`configIssue${extraPayment.code}`}
                                                                placement='bottom'
                                                                overlay={<Tooltip id={`config_issue_tool_tip${extraPayment.code}`}>
                                                                    {t(extraPayment.configIssueText)}
                                                                </Tooltip>}
                                                            >
                                                                <i className='fa fa-exclamation-circle text-danger ms-2' />
                                                            </OverlayTrigger>
                                                        }
                                                    </div>
                                                    <OverlayTrigger
                                                        key={extraPayment.code}
                                                        placement="top"
                                                        overlay={<Tooltip id={`tool_tip_${extraPayment.code}`}>
                                                            {extraPayment.hasConfigIssue ? t(extraPayment.configIssueText) : t(extraPayment.helpText)}</Tooltip>}
                                                    >
                                                        <div className='d-flex align-items-center'>
                                                            {extraPayment?.extraPaymentType?.value === 'fixed_hourly_amount' ?
                                                                <NumberInput
                                                                    style={{ maxWidth: '85px' }}
                                                                    customClassses="text-center"
                                                                    disabled={!extraPayment.isEditable}
                                                                    value={extraPaymentsInTimeSheet[extraPayment.code] ?
                                                                        extraPaymentsInTimeSheet[extraPayment.code].value
                                                                        : 0}
                                                                />
                                                                :
                                                                <InputTime
                                                                    disabled={!extraPayment.isEditable}
                                                                    value={extraPaymentsInTimeSheet[extraPayment.code] ?
                                                                        minutesToTimeInput(extraPaymentsInTimeSheet[extraPayment.code].value)
                                                                        : '00:00'
                                                                    }
                                                                    className="big-input text-center"
                                                                    onChange={(e) => timelogStore.handleChangeExtraPayments(extraPayment, e)}
                                                                />
                                                            }
                                                        </div>
                                                    </OverlayTrigger>
                                                </Col>
                                            ))}
                                        </div>
                                        {!!__extraPaymentRules?.length &&
                                            <div className='font-size-15'>
                                                {t('Extra hours are calculated based on the settings configured in the control panel under "additional payments"')}.
                                            </div>
                                        }
                                    </>
                                    {this.visualState().normalEditing && (tipEditor === "member") && (
                                        <>
                                            <h3 className="timelog-grid__title">{t('Tip Entry')}</h3>
                                            <div className="timelog-grid-time-card">
                                                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className='timelog-grid-overtime-item justify-content-space-between card-timelog-grid-item'>
                                                    <span className="timelog-grid__text timelog-grid__text-capitalize d-flex align-items-center" style={{ flex: 1 }}>
                                                        {t("Tip Amount")}
                                                    </span>
                                                    <NumberInput
                                                        className="timelog-tip-entry"
                                                        value={tipAmount}
                                                        onChange={(evt) => { this.handleChange('data.tipInfo.amount', evt.target.value) }}
                                                    />
                                                </Col>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </AccordionItem>
                        {!biztypeData.timelog_hide_kilometers && this.visualState().normalEditing && (
                            <AccordionItem title={t('Register Distance')} uuid="km">
                                <div className="register-dist">
                                    <div className="register-dist__main-input">
                                        <img src={distanceIcon} alt="Distance" />
                                        <div>
                                            <NumberInput
                                                className="timelog-tip-entry"
                                                value={data.distance}
                                                onChange={(e) => this.handleChange('data.distance', e.target.value)}
                                            />
                                            <span className='ms-3'>{distanceUnitType(dateTimeRules)}</span>
                                        </div>
                                    </div>
                                    <div className="register-dist__inputs">
                                        <Col xs={12} md={6} >
                                            <Input
                                                label={t('Workplace 1')}
                                                className="register-dist__input w-100 timelog-array-custom-margin"
                                                value={data.address}
                                                onChange={(e) => this.handleChange('data.address', e.target.value)}
                                                placeholder={t('Address')}
                                            />
                                        </Col>
                                        <Col xs={12} md={6} >
                                            <Input
                                                label={t('Workplace 2')}
                                                className="register-dist__input w-100 timelog-array-custom-margin"
                                                value={data.workplace}
                                                onChange={(e) => this.handleChange('data.workplace', e.target.value)}
                                                placeholder={t('Address')}
                                            />
                                        </Col>
                                    </div>
                                </div>
                            </AccordionItem>
                        )}
                        {!biztypeData.timelog_hide_kilometers && this.visualState().normalEditing && (
                            <AccordionItem title={t('Extra Passangers')} uuid="extrapass">
                                <DynamicList
                                    placeholder={t('Enter passanger name')}
                                    label={t('Passanger Name')}
                                    onItemsChange={(passangers) => this.handleChange('data.passangers', passangers)}
                                    initData={data.passangers}
                                />
                            </AccordionItem>
                        )}
                        {!biztypeData.timelog_hide_other && this.visualState().normalEditing && (
                            <AccordionItem title={t('Other Expenses')} uuid="otherexp">
                                <TimelogArray
                                    mode="expenses"
                                    options={configExtraExpenses}
                                    onSave={(expenses) => this.handleChange('data.expenses', expenses)}
                                    initData={data.expenses}
                                />
                            </AccordionItem>
                        )}
                        {!biztypeData.timelog_hide_extra && this.visualState().normalEditing && (
                            <AccordionItem title={t('Extra Work')} uuid="extraw">
                                <TimelogArray
                                    mode="work"
                                    options={configExtraWorks}
                                    onSave={(extraWork) => this.handleChange('data.extraWork', extraWork)}
                                    initData={data.extraWork}
                                />
                            </AccordionItem>
                        )}
                        {this.visualState().normalEditing && (
                            <AccordionItem title={t('Signature')}>
                                <Signature
                                    onChange={(signature) => this.handleChange('data.signature', signature)}
                                    initialSignature={signature}
                                    required
                                    width="100%"
                                    height={250}
                                    error={false && !signature}
                                />
                            </AccordionItem>
                        )}
                        {this.visualState().normalEditing && (
                            <AccordionItem title={t('Final Step')} uuid="final">
                                <div className="timelog-final">
                                    <div className="timelog-final__item">
                                        <h4 className="timelog-grid__title mt-0">{t('Other Documents')}</h4>
                                        <div className='d-flex w-100 attachment-box'>
                                            <AttachmentsWidget
                                                value={data.attachments}
                                                onChange={(attachIds) => this.handleChange('data.attachments', attachIds)}
                                                options={{
                                                    checkDate: true,
                                                    imageContext: {
                                                        existingAttachment: Attachments,
                                                        fileType: 'docs',
                                                        id: 0,
                                                        model: 'Timelog',
                                                    },
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="timelog-final__item">
                                        <Textarea
                                            label={t('Comment') + ('*')}
                                            value={description}
                                            style={{ width: '100%' }}
                                            onChange={(e) => this.handleChange('description', e.target.value)}
                                            placeholder={t('Enter comments here')}
                                        />
                                    </div>
                                    <div className="timelog-final__item">
                                        <div className='row timelog-grid-time-card'>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className='timelog-grid-item-1 justify-content-space-between card-timelog-grid-item p-3 align-items-center'>
                                                {t('Working hours')}: <br />
                                                <b className='timelog-final-time-card'>{_total_hours_normal ? minutesToTimeInput(_total_hours_normal, false) : total_hours_normal}</b>
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className='timelog-grid-item-2 justify-content-space-between card-timelog-grid-item p-3 align-items-center'>
                                                {t('Overtime hours')}: <br />
                                                <b className='timelog-final-time-card'>{_total_hours_overtime ? minutesToTimeInput(_total_hours_overtime, false) : total_hours_overtime}</b>
                                            </Col>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex timelog-save-button'>
                                    {this.visualState().allowSave && currentEntity.status === 'draft' && (
                                        <>
                                            <Col xs={12} md={2}>
                                                <Button
                                                    fill dynamic_lg btn_mute
                                                    onClick={() => this.intermediateSave()}
                                                >
                                                    {t('Save as draft')}
                                                </Button>
                                            </Col>
                                            <Col xs={12} md={2}>
                                                {!this.visualState().trackerIsRunning && (
                                                    <Button
                                                        fill dynamic_lg
                                                        onClick={() => this.fullSave(true)}
                                                    >
                                                        {t('Save and publish')}
                                                    </Button>
                                                )}
                                            </Col>
                                        </>
                                    )}
                                    {this.visualState().allowSave && currentEntity.status !== 'draft' && (
                                        <Button
                                            fill dynamic_lg
                                            onClick={() => this.fullSave(false)}
                                        >
                                            {t('Save')}
                                        </Button>
                                    )}
                                </div>
                            </AccordionItem>
                        )}
                    </Accordion>
                    {this.visualState().showStatus && (
                        <AcceptFormBlock
                            params={{ id, status, entity: 'timelog' }}
                            afterSave={() => this.handleChangeStatus()}
                        />
                    )}
                </div>
            </>
        );
    }
}

export default inject('timelogStore', 'commonStore', 'userStore')(applicationRouter(withLocalization(observer(TimelogsForm))));
