import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { cloneDeep } from 'lodash';
import moment from 'moment/min/moment-with-locales';

//componets
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import AcceptFormBlock from '../Timelogs/AcceptFormBlock';
import AbsencesTotals from './AbsencesTotals';
import GenericForm from '~/components/GenericForm/GenericForm';

//elements
import LoadingSpinner from '~/elements/LoadingSpinner';

//utils
import customerFeature from '~/utils/customerFeature';

//schema
import getSchema from '~/library/schemas/absence';

const defaultUiSchema = ({ user_type }) => {
    if (user_type === 'member')
        return {
            'ui:field': 'layout',
            'ui:layout:hideframe': true,
            'ui:layout': [
                {
                    absence_type: { xs: 6, sm: 2, className: "mt-sm-2" },
                    leave_type: { xs: 6, sm: 2, className: "mt-sm-2" },
                    project_id: { xs: 6, sm: 4, md: 4 },
                    child: { xs: 6, sm: 4, doShow: ({ formData }) => formData.absence_type === 'child', className: "mt-sm-2" },

                },
                {
                    from: { xs: 6, sm: 4, className: "mt-3" },
                    to: { xs: 6, sm: 4, className: "mt-3" },
                    total_days: { xs: 6, sm: 4, className: "mt-3" },
                },
                {
                    employement_grade: { xs: 6, sm: 6, md: 4, className: "mt-sm-2" },
                    hours: { xs: 6, sm: 3, md: 4, className: "mt-3" },
                    total_hours: { xs: 6, sm: 3, md: 4, className: "mt-3" },
                },
                {
                    'ui:className': 'mt-4',
                    comments: { md: 12 },
                },
                {
                    'ui:className': 'mt-2',
                    attachments: { md: 12 },
                },
            ],
            absence_type: {
                'ui:widget': 'AbsenceStatuses',
            },
            leave_type: {
                'ui:widget': 'LeaveTypeWidget',
            },
            child: {
                'ui:widget': 'KidsSelect',
            },
            attachments: {},
            user_id: {
                'ui:widget': 'AllUsersWidget',
            },
            project_id: {
                'ui:widget': 'ProjectsWidget',
            }
        }


    return {
        'ui:field': 'layout',
        'ui:layout:hideframe': true,
        'ui:layout': [
            {
                child: {
                    xs: { span: 6, order: 2 }, sm: 4,
                    doShow: ({ formData }) => formData.absence_type === 'child', className: "mt-sm-2"
                },
            }
            ,
            {
                absence_type: { xs: 6, sm: 4, md: 2, className: "mt-sm-2" },
                leave_type: { xs: 6, sm: 4, md: 2, className: "mt-sm-2" },
                project_id: { xs: 6, sm: 4, md: 4 },
                employement_grade: { xs: 6, sm: 4, md: 4, className: "mt-sm-2" }
            },
            {
                from: { xs: 6, sm: 3, md: 4, className: "mt-3" },
                to: { xs: 6, sm: 3, md: 4, className: "mt-3" },
                hours: { xs: 6, sm: 3, md: 2, className: "mt-3" },
                total_hours: { xs: 6, sm: 3, md: 2, className: "mt-3" },
            },
            {
                total_days: { xs: 6, sm: 3, md: 4, className: "mt-3" },
                absence_payment_per_day: { xs: 6, sm: 3, md: 4, className: "mt-3" },
                absence_total_payment: { xs: 12, sm: 3, md: 4, className: "mt-3" }
            },
            {
                'ui:className': 'mt-4',
                comments: { md: 12 },
            },
            {
                'ui:className': 'mt-2',
                attachments: { md: 12 },
            },
        ],
        absence_type: {
            'ui:widget': 'AbsenceStatuses',
        },
        leave_type: {
            'ui:widget': 'LeaveTypeWidget',
        },
        child: {
            'ui:widget': 'KidsSelect',
        },
        attachments: {},
        user_id: {
            'ui:widget': 'AllUsersWidget',
        },
        project_id: {
            'ui:widget': 'ProjectsWidget',
            'ui:user_id': null
        }
    }
};

class AbsencesForm extends Component {
    constructor(props) {
        super(props);
        this.extraPaymentRules = props.commonStore.config.client.data.extraPayments;
        this.state = {
            schema: getSchema({
                user_type: this.props.userStore.currentUser.user_type,
                config: this.props.commonStore.config
            }),
            uiSchema: defaultUiSchema({ user_type: this.props.userStore.currentUser.user_type }),
            formTimeStamp: Date.now()
        };
        this.onSave = this.onSave.bind(this)
    }

    getListUrl() {
        return '/admin/absences';
    }

    async handleChangeStatus() {
        this.loadData();
    }

    async loadData() {
        const { absenceStore, location } = this.props;
        this.setState({
            schema: getSchema({
                user_type: this.props.userStore.currentUser.user_type,
                config: this.props.commonStore.config
            }),
        });

        let getId = (this.props.router.location.state && this.props.router.location.state.absence) || null;
        if (!getId) {
            this.setState({ uiSchema: this.prepareSchema(defaultUiSchema({ user_type: this.props.userStore.currentUser.user_type }), null) });
            return absenceStore.returnDefaultNew({ location, user_id: this.props.userStore.currentUser.id });
        }
        const absence = await absenceStore.load(getId, !getId);
        this.setState({ uiSchema: this.prepareSchema(defaultUiSchema({ user_type: this.props.userStore.currentUser.user_type }), absence.absence) });
    }

    prepareSchema(_schema, currentEntity) {
        const schema = cloneDeep(_schema);
        const { userStore } = this.props;
        const { currentUser } = userStore;

        schema.attachments = {
            'ui:widget': 'AttachmentsWidget',
            'ui:imageContext': {
                model: 'Absence',
                fileType: 'docs',
                id: currentEntity && currentEntity.id !== 'add' ? currentEntity.id : 0,
                existingAttachment: currentEntity && currentEntity.Attachments ? currentEntity.Attachments : [],
            },
        };
        const showUser = (currentUser.user_type == 'admin' || currentUser.user_type == 'pm')
            && customerFeature('pm_allow_editing_all')
            && !customerFeature('disallow_absences_vacations_not_self');

        if (showUser) {
            schema['ui:layout'][0].user_id = { xs: 6, sm: 4, className: "mt-sm-2" };
        }

        if (currentUser.user_type === 'member') {
            if (schema.user_id) schema.user_id.classNames = 'hidden';
        }

        if (currentUser.user_type === 'pm') {
            schema.project_id = {
                'ui:widget': 'ProjectsWidget',
                'ui:user_id': currentUser.id
            }
        }

        if (currentEntity && currentEntity.absence_type) {
            schema.absence_type['ui:widget'] = 'AbsenceStatusesOnEdit';
            schema.leave_type['ui:widget'] = 'LeaveTypeWidgetOnEdit';
        }
        return schema;
    }

    componentWillMount() {
        this.props.absenceStore.loading = true;
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(previousProps) {
        const { location } = this.props;
        if (location !== previousProps.location) {
            this.loadData();
        }
    }

    onSave(values) {
        const { absenceStore, t, commonStore } = this.props;
        const { userStore } = this.props;
        const { currentUser } = userStore;
        const { biztype } = commonStore.config;

        let getId = (this.props.router.location.state && this.props.router.location.state.absence) || null;
        if (currentUser.user_type === 'member' && !getId) {
            values.user_id = currentUser.id;
        }
        const allow = (currentUser.user_type == 'admin' || currentUser.user_type == 'pm')
            && customerFeature('pm_allow_editing_all')
            && !customerFeature('disallow_absences_vacations_not_self');

        if (currentUser.id != values.user_id && !allow) {
            commonStore.addNotification(t('Can not save on behalf of others'), null, 'error');
            return false;
        }

        if (values.absence_type == 'self' && values.leave_type == 'paid' && moment(values.from).isBefore(moment().add(-1, 'year'), 'date')) {
            return commonStore.addNotification(t('Can not add or update sick leave older than 1 year'), null, 'error');
        }

        if (!values.total_days) {
            return commonStore.addNotification(t("Total days should be atleat 1 day, please check if you have selected holidays"), null, 'warning');
        }

        if (commonStore.config?.integration_details?.product == 'visma'
            && values.total_days > 3 && values.absence_type === 'doctor'
            && !values.data.medical_certification_percent && !values.data.doctor_certification_date) {
            commonStore.addNotification(t("Please add Doctor certificate details if you want to sync this to integration system"), null, 'warning');
        }

        if (!values.project_id && biztype.data && biztype.data.allow_multiple_branches) {
            return commonStore.addNotification(t("Please select Project (Branch) and then try again"), null, 'warning');
        }

        return absenceStore.save(values, !getId).then(result => {
            if (!result.absence || !result.absence.id) {
                commonStore.addNotification(t(result.message || 'Error'), null, 'error');
                return false;
            }
            const cUser = this.props.userStore.currentUser;
            if (result.absence.user_id === cUser.id) {
                this.props.userStore.pullUser().then(() => {
                    commonStore.addNotification(t('Saved'), null, 'success');
                    this.getBack()
                    return true;
                });
            } else {
                commonStore.addNotification(t('Saved'), null, 'success');
                this.getBack()
                return true;
            }
        });
    }

    validateAbsence(formData, errors) {
        const { t, commonStore } = this.props;
        const { config } = commonStore
        const fromToDiff = moment(formData.to).diff(formData.from, 'days') + 1;

        if (formData.to && formData.from && moment(formData.from).isAfter(moment(formData.to))) {
            errors.from.addError(t("From date should be before to date"));
        }
        if (formData.absence_type && formData.leave_type && formData.absence_type == 'self' && formData.leave_type == 'paid' && formData.total_days > 3) {
            errors.to.addError(t("Self-clarification is max 3 days"));
        }
        if (formData.absence_type && formData.to && formData.leave_type && formData.absence_type == 'self' && formData.leave_type == 'paid' && moment(formData.to).isAfter(moment().add(3, 'days'))) {
            errors.to.addError(t("Only up to 3 future days are valid."));
        }
        if (formData.absence_type && formData.from && formData.leave_type && formData.absence_type == 'self' && formData.leave_type == 'paid' && moment(formData.from).isBefore(moment().add(-1, 'year').add(2, 'days'))) {
            errors.from.addError(t("Cannot apply for sick leave older than 1 year."));
        }
        if (formData.absence_type && formData.absence_type === 'doctor' && fromToDiff > 3 && config?.integration_details?.product === 'visma') {
            if (!moment(formData?.data?.doctor_certification_date).isBetween(formData?.from, formData?.to, "date", "[]")) {
                errors?.data?.doctor_certification_date?.addError(t("Doctor Certificate should be between \"From date\" and \"To date\""))
            }
            if (formData?.data?.medical_certification_percent > 100 || !formData?.data?.medical_certification_percent) {
                errors?.data?.medical_certification_percent?.addError(t('Medical certificate percentage should be between 1 to 100'))
            }
        }
        if (!formData.employement_grade || isNaN(formData.employement_grade) || formData.employement_grade > 100) {
            errors.employement_grade.addError(t("Leave percentage should be between 1 to 100"));
        }
        return errors;
    }

    getBack = () => {
        const { page } = this.props.router.location.state || {};
        this.props.router.navigate('/admin/absences', { state: { page: page, isEdit: true } });
    }

    updateSchema = (all, oldData) => {
        const { schema, formData, uiSchema } = all;
        const _schema = cloneDeep(schema);
        if (formData?.absence_type === 'child' && _schema.required.indexOf('child') === -1) {
            _schema.required.push('child');
            this.setState({ schema: _schema });
        }
        else {
            if (formData?.absence_type !== 'child' && _schema.required.indexOf('child') !== -1) {
                _schema.required.splice(_schema.required.indexOf('child'), 1);
                this.setState({ schema: _schema });
            }
        }

        const fromToDiff = moment(formData.to).diff(formData.from, 'days') + 1;
        if (fromToDiff) {
            if (fromToDiff > 3 && formData?.absence_type === "doctor") {
                const _uiSchema = cloneDeep(uiSchema);
                if (_uiSchema && _uiSchema['ui:layout']?.length && _uiSchema['ui:layout'].find(ele => ele['ui:subtitle'] === 'Required payroll integration info'))
                    return;

                if (schema.properties.data) {
                    _uiSchema['ui:layout'].splice(_uiSchema['ui:layout'].length - 1, 0, {
                        'ui:subtitle': 'Required payroll integration info',
                        'ui:className': 'custom_style_integration_details py-2',
                        data: { xs: 12, sm: 8 },
                    });
                    this.setState({ uiSchema: _uiSchema })
                }
            }
            else {
                const _uiSchema = cloneDeep(uiSchema);
                const index = _uiSchema['ui:layout']?.findIndex(ele => ele['ui:subtitle'] === 'Required payroll integration info')
                if (index > 0) {
                    _uiSchema['ui:layout'].splice(index, 1);
                    this.setState({ uiSchema: _uiSchema })
                }
            }
        }
    }

    render() {
        const { absenceStore, add } = this.props;
        const { loading, currentEntity } = absenceStore;
        const { schema, uiSchema, formTimeStamp } = this.state;
        const { user_type, id: userId } = this.props.userStore.currentUser;
        const showStatus = (user_type === 'pm' || user_type === 'admin') && customerFeature('allow_accepting') && !add;
        let allow = (user_type == 'admin' || user_type == 'pm')
            && customerFeature('pm_allow_editing_all')
            && !customerFeature('disallow_absences_vacations_not_self');
        allow = !add && currentEntity && currentEntity.absence && currentEntity.absence.user_id != userId && !allow;

        if (currentEntity && currentEntity.absence && currentEntity.absence.status == 'approved') allow = true;
        if (currentEntity && currentEntity.absence && currentEntity.absence.absence_payment_per_day > 0) {
            currentEntity.absence.absence_total_payment = currentEntity.absence.absence_payment_per_day * currentEntity.absence.total_days;
        }

        if (loading) return <LoadingSpinner />;

        return (
            <div className="primary-page">
                <AbsencesTotals
                    key={`${currentEntity.absence.user_id}-${moment().isBefore(moment(currentEntity.absence.from)) ? currentEntity.absence.from : "current"}`}
                    user_id={currentEntity.absence.user_id}
                    for_date={currentEntity.absence.from}
                    absenceStatus={currentEntity.absence.status}
                    forceReloadForm={() => this.setState({ formTimeStamp: Date.now() })}
                />
                <GenericForm
                    key={formTimeStamp}
                    entity={currentEntity.absence}
                    uiSchema={uiSchema}
                    schema={schema}
                    translationScope="forms.absence"
                    onChange={async data => absenceStore.recalc(data, this.extraPaymentRules)}
                    onSave={this.onSave}
                    listUrl={this.getListUrl()}
                    isAdding={!!this?.props?.router?.location?.state?.absence ? true : false}
                    disallowSave={allow}
                    customvalidate={(formData, errors) => this.validateAbsence(formData, errors)}
                    goBack={() => this.getBack()}
                    updateSchema={this.updateSchema}
                />
                {showStatus && !!this?.props?.router?.location?.state?.absence && (
                    <AcceptFormBlock
                        params={{ id: this.props.router.location.state.absence, status: currentEntity.absence.status, data: currentEntity, entity: 'absence' }}
                        afterSave={() => this.handleChangeStatus()}
                    />
                )}
            </div>
        );
    }
}

export default inject('absenceStore', 'commonStore', 'userStore')(applicationRouter(withLocalization(observer(AbsencesForm))));
