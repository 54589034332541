import { observable, action, makeObservable } from 'mobx';
import agent from '../agent';

class ShiftAllowanceStore {
    constructor() {
        makeObservable(this, {
            loading: observable,
            lastListLoadTime: observable,
            filters: observable,
            appliedFilters: observable,
            resetLastListLoadTime: action,
            setFilter: action,
            onFilter: action,
            loadList: action,
            save: action,
            remove: action,
            allShiftAllowance: observable
        });
    }

    loading = false;

    lastListLoadTime = null;

    allShiftAllowance = 0;

    resetLastListLoadTime() {
        this.lastListLoadTime = new Date();
    }

    filters = {
        status: '',
        name: '',
        shift_allowance_type: ''
    };

    appliedFilters = {
        status: '',
        name: '',
        shift_allowance_type: ''
    };

    setFilter(name, value) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
    }

    onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
    }

    loadList(params) {
        return agent.ShiftAllowance.list(params)
            .then(
                action(list => {
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    save(values, isAdd) {
        return agent.ShiftAllowance.save(values, isAdd)
            .then(
                action(data => {
                    return data;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    loadGroup(values) {
        this.loading = true
        return agent.ShiftAllowance.loadGroup(values)
            .then(
                action(data => {
                    this.loading = false
                    return data;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    async remove(id) {
        await agent.ShiftAllowance.remove(id);
        this.resetLastListLoadTime()
        return 1;
    }

    async removeEmergencyShift(id) {
        await agent.ShiftAllowance.removeEmergencyShift(id);
        return 1;
    }

    async getShiftAllowanceCount(params) {
        await agent.ShiftAllowance.getShiftAllowanceCount(params).then(data => {
            this.allShiftAllowance = data.shift_allowance;
        }).catch(
            action(err => {
                throw err;
            })
        );
    }

}

const _ShiftAllowanceStore = new ShiftAllowanceStore();
export default _ShiftAllowanceStore;