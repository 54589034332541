import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col } from 'react-bootstrap'

//components
import applicationRouter from '~/hoc/applicationRouter';
import withLocalization from '~/hoc/withLocalization';
import InputTime from '../../../../components/GenericForm/Widgets/InputTime';
import TimePickerInput from '../../../../components/TimePicker';

//utils
import { getMinutesFromTimeInput, minutesToTimeInput } from "../../../../utils/timeCalc";

class RulesForStartTime extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rulesForStartTime: {
                end: "00:00",
                start: "00:00",
                total: "00:00",
            },
        }
        this.handleTimeChange = this.handleTimeChange.bind(this)
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.breakInMins !== this.props.breakInMins) {
            const { rulesForStartTime } = this.state;
            const { breakInMins } = this.props;
            this.handleTotalTime(rulesForStartTime, breakInMins);
            this.props.handleUserDataChange('startTimeRules', rulesForStartTime);
            this.setState({ rulesForStartTime });
        }
    }


    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        const { rulesForStartTime } = this.state;
        if (currentEntity.client.data && currentEntity.client.data.basicRules) {
            const basicRules = Object.assign({}, currentEntity.client.data.basicRules);
            rulesForStartTime.start = basicRules.startTimeRules.start;
            rulesForStartTime.end = basicRules.startTimeRules.end;
            rulesForStartTime.total = basicRules.startTimeRules.total;
            this.setState({ rulesForStartTime })
        }
    }

    handleTimeChange = (name, value) => {
        const { rulesForStartTime } = this.state;
        const { breakInMins } = this.props;
        rulesForStartTime[name] = value;
        if (name !== "total") {
            this.handleTotalTime(rulesForStartTime, breakInMins);
        }
        this.props.handleUserDataChange('startTimeRules', rulesForStartTime);
        this.setState({ rulesForStartTime });
    }

    handleTotalTime = (rulesForStartTime, breakInMins) => {
        const totalTimeInMinutes = getMinutesFromTimeInput(rulesForStartTime.end) - getMinutesFromTimeInput(rulesForStartTime.start) - breakInMins;
        rulesForStartTime.total = minutesToTimeInput(totalTimeInMinutes);
    }


    render() {
        const { rulesForStartTime } = this.state;
        const { t } = this.props;
        const { currentEntity } = this.props.clientStore;
        const { dateTimeRules } = currentEntity.client.data;
        const format = dateTimeRules && dateTimeRules.time_format ? dateTimeRules.time_format : 'hh:mm';
        return (
            <Row>
                <Col sm={4} xs={4}>
                    <label className="control-label">{t('Start time')}
                        <span className="text-muted "><em> (hh:mm)</em></span></label>
                    <TimePickerInput
                        style={{ width: 150 }}
                        value={rulesForStartTime.start}
                        format={format == "hh:mm" ? "HH:mm" : format}
                        onChange={value => {
                            this.handleTimeChange('start', value);
                        }}
                    />
                </Col>
                <Col sm={4} xs={4}>
                    <label className="control-label">{t('End time')}
                        <span className="text-muted "><em> (hh:mm)</em></span></label>
                    <TimePickerInput
                        style={{ width: 150 }}
                        value={rulesForStartTime.end}
                        format={format == "hh:mm" ? "HH:mm" : format}
                        onChange={value => {
                            this.handleTimeChange('end', value);
                        }}
                    />
                </Col>
                <Col sm={4} xs={4}>
                    <label className="control-label">{t('Normal Work Day')}
                        <span className="text-muted "><em> (hh:mm)</em></span>
                    </label>
                    <InputTime
                        width={"auto"}
                        disabled
                        value={rulesForStartTime.total}
                    />
                </Col>
            </Row>
        );
    }
}
export default inject('clientStore', 'commonStore', 'userStore')(applicationRouter(withLocalization(observer(RulesForStartTime))));
